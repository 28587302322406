import { useEffect, useState } from "react";
import Request from "../../../api/Requests";
import Loading from "../../../components/Loading";
import Menu from "../Menu";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { isVisible } from "@testing-library/user-event/dist/utils";
import BottomSheetModal from "../../Layout/BottomSheetModal/BottomSheetModal";

const TicketsScreen = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [state, setState] = useState(null)
    const [modalQrcodeModal, setModalQrcodeModal] = useState({
        isVisible: false,
        qrCodeUrl: null
    })
    const getTicketList = async () => {
        setIsLoading(true)
        const response = await Request('reserved-area/get-list-tickets', 'GET', {}, true);
        if (response && response.status) {
            setState(response.data)
        } else {

        }
        setIsLoading(false)
    }

    const showQrcodeModal = (qrcodeUrl) => {
        console.log(qrcodeUrl)
        setModalQrcodeModal({
            ...modalQrcodeModal,
            isVisible: true,
            qrCodeUrl: qrcodeUrl
        })
    }
    const hideQrcodeModal = () => {
        setModalQrcodeModal({
            ...modalQrcodeModal,
            isVisible: false,
            qrCodeUrl: null
        })
    }


    const TicketsList = () => {
        const EventViewer = ({ event }) => {
            return (
                <>

                    <div className={`row event-item`}>
                        <div className="col-2">
                            <img src={event.banner} alt={event.name} style={{ height: '50px', width: 'auto' }} className="img-fluid event-img" />
                        </div>
                        <div className="col-10">
                            <h5 className="text-light">{event.name}</h5>
                            <div className="mt-3" style={{ fontSize: '0.9rem' }}>
                                {event.date_formated}
                                <small className="mx-3" style={{ color: 'rgb(234, 44, 97)' }}>|</small>
                                <span style={{ color: '#939393' }}>{event.hour_start} - {event.hour_end}</span>
                            </div>

                        </div>
                    </div>
                </>
            )
        }

        const StatusTicket = ({ item }) => {
            return item.status == "COMPLETED" ? (
                <div className="row" >
                    <div className="col-12">
                        <button className="btn btn-primary w-100" onClick={() => showQrcodeModal(item.qrcodeUrl)}><i className="fa-solid fa-qrcode me-3"></i>Mostrar QR Code</button>
                    </div>
                </div>
            ) : (
                <div className="row mt-4" >
                    <div className="col-12">
                        <div className="alert alert-danger" role="alert">
                            <h4 className="alert-heading">Atenção</h4>
                            <p>Efetue o pagamento e espere que o mesmo seja confirmado.</p>
                            <hr />
                            <p className="mb-0">O pagamento por mbway permanece ativo por apenas 5 minutos.</p>
                        </div>
                    </div>
                </div>
            )
        }

        const SemResultados = () => {
            return (
                <div className="alert alert-info mt-3" role="alert">
                    <i className="fa-solid fa-info-circle me-3"></i>
                    Não existe bilhetes comprados.
                </div>
            )
        }



        return state.tickets && state.tickets.length == 0 ? <SemResultados /> : state.tickets.map((item) => {
            return (
                <div className="row">
                    <div className="col-12">
                        <EventViewer event={item.event} />
                        
                        <div className="row">
                            <div className="col-12">
                                <h5 className="text-light">Bilhete: {item.ticket.name}</h5>
                            </div>
                        </div>
                    </div>
                    <StatusTicket item={item} />
                    <hr className="mt-3" />
                </div>
            )
        })
    }

    const ModalQrcodeModal = () => {
        return !modalQrcodeModal.qrCodeUrl ? null : (
            <BottomSheetModal isVisible={modalQrcodeModal.isVisible} onClose={hideQrcodeModal} height="35vh">
                <div style={{ padding: '20px' }}>
                    <div className="row mb-2">
                        <div className="col-12 text-center">
                            <h4>Apresente o seguinte QR Code à entrada</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center">
                            <div style={{ display: 'inline-block', backgroundColor: '#fff' }}>
                                <img
                                    src={modalQrcodeModal.qrCodeUrl}
                                    style={{ display: 'block' }}
                                    alt="QR Code"
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </BottomSheetModal>
        )
    }


    useEffect(() => {
        getTicketList()
    }, [])


    return (isLoading ? <Loading /> :
        <>
            <div className="container-fluid">
                <div className="row mb-3 justify-content-center">
                    <div className="col-12 col-sm-6">
                        <div className="position-relative z-1">
                            <div className="box-tables mt-5">

                                <Menu tab="tickets" />

                                <TicketsList />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <ModalQrcodeModal
            />
        </>
    )
}

export default TicketsScreen;