import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GuestlistAccessRequests from "./GuestlistAccessRequests";
import './Guestlist.css'
import SelectCountries from "../../Layout/Forms/SelectCountries";
import Input from "../../Layout/Forms/Input";
import Loading from "../../../components/Loading";
import ReCAPTCHA from 'react-google-recaptcha';
import ModalTablesImage from "./ModalTablesImage"
import TicketsRequests from "../tickets/TicketsRequests";


const GuestListAccessEvent = ({ type }) => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const urlParams = useParams();
    let captcha;
    const setCaptchaRef = (ref) => {
        if (ref) {
            return captcha = ref;
        }
    };

    const refCaptcha = useRef(null);
    const [state, setState] = useState({
        form: {
            phone_country: '351',
            phone_number: '',
            email: '',
            name: '',
            recaptcha: ''
        },
        errors: [],
        countryList: [],
        event: {}
    });
    const [quantityTickets, setQuantityTickets] = useState({}); // Inicializa como objeto vazio
    const [guestListRegister, setGuestListRegister] = useState(false)
    const [modalImageTables, setModalImageTables] = useState({
        isVisible: false
    })

    const [isVerified, setIsVerified] = useState(false);
    const handleRecaptchaChange = (token) => {
        setIsVerified(true);
        handleChangeInputs({
            target: {
                name: "recaptcha",
                value: token
            }
        })
    };

    useEffect(async () => {
        let response = await GuestlistAccessRequests.getInfoEvent(urlParams, type);
        if (response && response.status == true) {
            setState(response.data)
        } else {
            //navigate(`/`);
        }
        setIsLoading(false)
    }, [])



    const handleChangeInputs = (e) => {

        if (!isLoading) {
            setState({
                ...state,
                form: {
                    ...state.form,
                    [e.target.name]: e.target.value
                }
            });
        }
    }

    const seriaizeCountryList = () => {
        if (state.countryList && state.countryList.length > 0)
            return state.countryList.map((item) => {
                return {
                    label: item.name,
                    value: item.phone_country,
                    icon: item.icon
                }
            });
    }

    const handlerSubmitGuestlist = async (e) => {
        // setIsLoading(true);
        e.preventDefault();


        // console.log(state.form.phone_number.length == 0);
        // return;
        let errorArray = {};
        let error = false;
        if (state.form.phone_number.length == 0) {
            errorArray = {
                ...errorArray,
                ...{
                    phone_number: [
                        'Preencha o Nº Telemóvel'
                    ]
                }
            }
            error = true;
        }

        if (state.form.name.length == 0) {
            errorArray = {
                ...errorArray,
                ...{
                    name: [
                        'Insira o seu nome'
                    ]
                }
            }
            error = true;
        }
        if (state.form.email.length == 0) {
            errorArray = {
                ...errorArray,
                ...{
                    email: [
                        'Insira o seu email'
                    ]
                }
            }
            error = true;
        }

        if (error) {
            toast.error("Preencha os campos obrigatórios", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });

            setState({
                ...state,
                errors: errorArray
            })
            // setIsLoading(false);
            return false;
        }

        // Chamar o reCAPTCHA para obter o token de verificação
        //const recaptchaValue = refCaptcha.current.getValue();
        const recaptchaValue = await refCaptcha.current.executeAsync();
        console.log(recaptchaValue)
        if (recaptchaValue) {
            submitForm(recaptchaValue);
        } else {
            toast.error("Verifique o reCAPTCHA antes de submeter o formulário.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }


        // if (isVerified && state.form.recaptcha) {
        //     let response = await GuestlistAccessRequests.submitEnterGuestlist({ ...urlParams, ...state.form }, type);
        //     if (response && response.status == true) {
        //         //navigate(`/${type == `venue` ? `v` : `u`}/${urlParams.slug}`);
        //         navigate(`/guestlist-complete`);
        //     } else {
        //         //navigate(`/`);
        //     }
        // } else {
        //     toast.error("Verifique o reCAPTCHA antes de submeter o formulário.", {
        //         position: "top-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "dark",
        //     });
        // }
        // setIsLoading(false)
    }

    const submitForm = async (recaptchaValue) => {
        setIsLoading(true);
        const formWithToken = { ...state.form, recaptcha: recaptchaValue }
        let response = await GuestlistAccessRequests.submitEnterGuestlist({ ...urlParams, ...formWithToken }, type);
        if (response && response.status == true) {
            setGuestListRegister(true)
            //navigate(`/guestlist-complete`);
        } else {
            // handle error
        }
        setIsLoading(false)
    }

    const handleShowInfo = () => {
        setState({
            ...state,
            event: {
                ...state.event,
                infoDisplay: true
            }
        })
    }


    const EventInfo = () => {

        const Info = () => {
            if (state.event.infoDisplay) {
                return (
                    <div className="row p-2" style={{ height: '20vh', overflowY: 'scroll' }}>
                        <div className="col-sm-12" dangerouslySetInnerHTML={{ __html: state.event.description }}>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="row p-2" onClick={() => handleShowInfo()}>
                        <div className="col-sm-12 text-center">
                            + Info&nbsp;<i className="fa-solid fa-chevron-down"></i>
                        </div>
                    </div>
                )
            }

        }

        return (
            <>


                <Info />
                <hr className="grad-line"></hr>
            </>
        )
    }

    const SectionLocation = () => {
        return (

            <div className="col-12 ">
                <h4>Localização</h4>
                <div className="mt-3" style={{ color: '#939393' }}>
                    {state.event.location_street}
                </div>
                <div className="mt-3">
                    <a href={`https://www.google.com/maps/@${state.event.latitude},${state.event.longitude},20z`} className="btn btn-primary" target="_blank"><i className="fa-solid fa-location-dot me-3"></i>Ver Mapa</a>
                </div>
            </div>
        )
    }

    const BtnShareSocial = () => {
        return (
            navigator.share ?
                (
                    // <div className="row ">
                    //     <div className="col align-self-end ">
                    //         <div className="position-relative">
                    //             <button className="btnShareSocial z-4" onClick={() => socialShare()}><i className="fa-solid fa-paper-plane"></i></button>
                    //         </div>
                    //     </div>
                    // </div>

                    <div className="position-relative">
                        <button className="btnShareSocial z-4" onClick={() => socialShare()}><i className="fa-solid fa-paper-plane me-2"></i>Partilhar</button>
                    </div>

                )
                : null
        )
    }

    const socialShare = () => {
        if (navigator.share) {
            navigator.share({
                title: `Nightspot | Guest list ${state.event.venue_name}`,
                text: `${state.event.venue_name} - ${state.event.name}`,
                url: window.location.href
            })
                .then(() => console.log('Shared successfully'))
                .catch(error => console.log('Error sharing:', error));
        } else {
            //alert("Native sharing is not supported");
        }
    }

    const GuestListSuccess = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="alert alert-success-ns" role="alert">
                        <p style={{ fontSize: '1.5rem' }}><i className="fa-solid fa-check me-3"></i>Sucesso</p>
                        <p style={{ fontSize: '1.3rem', lineHeight: '1.3rem' }}>Dentro breves minutos irá receber uma sms com QR Code de acesso.</p>
                        <p style={{ fontSize: '0.9rem', lineHeight: '0.9rem' }}>Pode instalar a nossa aplicação para um acesso rápido e agil ao seu qr code de acesso.</p>
                        <div className="row">
                            <div className="col-6 col-sm-2">
                                <a href="https://play.google.com/store/apps/details?id=nightspotapp.com" target="_blank">
                                    <img src="/img/social-network/google-play-badge.png" className="img-fluid" alt="google play download" />
                                </a>
                            </div>
                            <div className="col-6 col-sm-2">
                                <a href="https://apps.apple.com/app/nightspot-nightclubs-events/id6448525000" target="_blank">
                                    <img src="/img/social-network/app-store-badge.png" className="img-fluid" alt="AppStore download" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const TablesList = () => {

        const urlPromoterTable = `/${type == `venue` ? `v` : `u`}/${urlParams.slug}/${state.event.slug}`

        const SoldOut = ({ visibility }) => {
            return !visibility ? '' :
                (
                    <div className="badge-overlay">
                        <span className="badge-diagonal">Esgotado</span>
                    </div>
                )
        }

        const Hold = ({ visibility }) => {
            return !visibility ? '' :
                (
                    <div className="row my-4">
                        <div className="col-12 col-sm-6">
                            <div className="badge bg-warning text-dark" style={{ whiteSpace: 'normal' }}>
                                Em processo de reserva, esta mesa poderá voltar a ficar disponivel dentro de 5 minutos.
                            </div>
                        </div>
                    </div>
                )
        }

        const PriceBox = ({ visibility, price }) => {
            return !visibility ? '' :
                (
                    <div className="table-price">
                        {price}€
                    </div>
                )
        }

        return (
            state.tables.map((item, index) => {
                let linkTable = `${urlPromoterTable}/tables/${item.id}`
                if (item.status !== "PENDING") {
                    linkTable = "#"
                }
                return (
                    <Link key={index} className={`link-nostyle ${item.status !== "PENDING" ? `table-reserved` : ``}`} to={linkTable}>
                        <div className="row mt-4 mt-sm-3" >
                            <div className="col-12">
                                <div className="box-single-table containel-badge" style={{ borderColor: item.color }}>
                                    <SoldOut visibility={item.status == "RESERVED"} />
                                    <div className={`d-flex `}>
                                        <div className="flex-grow-1">
                                            <div className={`table-title ${item.status !== "PENDING" ? `opacity-50` : ``}`} >Mesa {item.name}</div>
                                            <Hold visibility={item.status == "HOLD"} />
                                            <div className={`table-description ${item.status !== "PENDING" ? `opacity-50` : ``}`} >
                                                {item.description}
                                            </div>
                                        </div>
                                        <div className={`align-self-center ${item.status !== "PENDING" ? `opacity-50` : ``}`}>
                                            <PriceBox price={item.base_price} visibility={item.status !== "RESERVED"} />
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </Link>
                )
            })

        )
    }

    const FloorImage = () => {
        return (state.tabled_img_url) ?
            (
                <>
                    <button className="btn btn-primary" type="button" onClick={() => setModalImageTables({
                        ...ModalTablesImage,
                        isVisible: true
                    })}><i className="fa-solid fa-image me-3"></i>Ver planta das mesas</button>

                </>
            ) : null
    }

    const handlerCreateTicketTransaction = async () => {
        if (quantityTickets && Object.keys(quantityTickets).length > 0) {
            let response = await TicketsRequests.generateTickettransaction({ ...urlParams, ...{ ticketsQuantity: quantityTickets, type: type } });
            if (response) {
                if (response.status == true) {
                    navigate(`/ticket/${response.data.transaction_id}`);
                } else {
                    toast.error(response.msg, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            }
        } else {
            toast.error("Selecione pelo menos um bilhete.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }


    }

    const getTicketsSelectedPrice = () => {
        let total = 0;
        state.tickets.map((item) => {
            if (quantityTickets[item.id]) {
                total += item.ticketPrice * quantityTickets[item.id];
            }
        });
        const totalFormatado = new Intl.NumberFormat('pt-PT', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(total);
        return totalFormatado;
    }
    
    const getTicketsSelectedQuantity = () => {
        let total = 0;
        state.tickets.map((item) => {
            if (quantityTickets[item.id]) {
                total += quantityTickets[item.id];
            }
        });
        return total;
    }
    
    const TicketList = () => {
        const urlPromoterTable = `/${type === `venue` ? `v` : `u`}/${urlParams.slug}/${state.event.slug}`;
        
        const handleIncrement = (id, maxQuantity) => {
            setQuantityTickets(prevQuantities => ({
                ...prevQuantities,
                [id]: ((prevQuantities[id] || 0) + 1) <= maxQuantity ? (prevQuantities[id] || 0) + 1 : maxQuantity
            }));
        };

        const handleDecrement = (id) => {
            setQuantityTickets(prevQuantities => {
                const newQuantity = Math.max((prevQuantities[id] || 0) - 1, 0);
        
                // Cria uma cópia do objeto atual
                const updatedQuantities = { ...prevQuantities };
        
                if (newQuantity === 0) {
                    // Remove a chave se o valor for zero
                    delete updatedQuantities[id];
                } else {
                    // Atualiza o valor da chave
                    updatedQuantities[id] = newQuantity;
                }
        
                return updatedQuantities;
            });
        };
        
        const SoldOut = () => {
            return (
                <div className="col-12 ticket-quantity text-center" style={{ color: '#d92d20' }}  >
                    Esgotado
                </div>

            );
        };

        const Hold = () => {
            return (
                <div className="col-12 ticket-quantity text-center"  >
                    Brevemente
                </div>
            );
        };

        const PriceBox = ({ price }) => {
            return (
                <div className="ticket-price text-center">
                    {price}€
                </div>
            );
        };

        const QuantityBox = ({ id, maxQuantity }) => {

            return (
                <>
                    <div className="col-3 ticket-quantity text-center" style={{ borderRight: '1px solid #4c4c4c' }} onClick={() => handleDecrement(id)}>
                        -
                    </div>
                    <div className="col-6 text-center">
                        <span style={{ fontSize: '16px', lineHeight: '33px' }}>{quantityTickets[id] || 0}</span>
                    </div>
                    <div className="col-3 ticket-quantity text-center" style={{ borderLeft: '1px solid #4c4c4c' }} onClick={() => handleIncrement(id, maxQuantity)}>
                        +
                    </div>
                </>
            )
        };

        
        return (
            <>
                {state.tickets.map((item) => {
                    let linkTable = `${urlPromoterTable}/tickets/${item.id}`;
                    if (item.status !== "AVAILABLE") {
                        linkTable = "#";
                    }

                    return (
                        <div key={item.id} className={`link-nostyle ${item.status !== "AVAILABLE" ? `table-reserved` : ``}`} >
                            <div className="row mt-4 mt-sm-3">
                                <div className="col-12">
                                    <div className="box-single-table containel-badge" style={{ borderLeft: 0, minHeight: '100px' }}>
                                        {/* <SoldOut visibility={item.status === "SOLD OUT"} /> */}
                                        <div className={`d-flex`} onClick={() => item.status !== "AVAILABLE" ? null : handleIncrement(item.id, item.ticketMaxOrder)}>
                                            <div className="flex-grow-1">
                                                <div className={`table-title ${item.status !== "AVAILABLE" ? `opacity-50` : ``}`}>{item.name}</div>

                                                <div className={`table-description ${item.status !== "AVAILABLE" ? `opacity-50` : ``}`}>
                                                    {item.description}
                                                </div>
                                            </div>
                                            <div className={`align-self-center ${item.status !== "AVAILABLE" ? `opacity-50` : ``}`}>
                                                <PriceBox price={item.ticketPrice} />
                                            </div>
                                        </div>
                                        {
                                            item.status === "AVAILABLE" ? (
                                                <div className="row mt-3 " style={{ border: '1px solid #4c4c4c', borderRadius: '6px' }}>
                                                    <QuantityBox id={item.id} maxQuantity={item.ticketMaxOrder} />
                                                </div>
                                            ) : null
                                        }
                                        {
                                            item.status === "SOLD OUT" ? (
                                                <div className="row mt-3 " style={{ border: '1px solid #4c4c4c', borderRadius: '6px' }}>
                                                    <SoldOut />
                                                </div>
                                            ) : null
                                        }
                                        {
                                            item.status === "SCHEDULE" ? (
                                                <div className="row mt-3 " style={{ border: '1px solid #4c4c4c', borderRadius: '6px' }}>
                                                    <Hold />
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    );
                })}
                {/* <button className={`btn btn-primary mt-3 w-100 ${quantityTickets && Object.keys(quantityTickets).length > 0 ? 'd-block' : 'd-none'}`} onClick={() => handlerCreateTicketTransaction()}>Confirmar</button> */}
            </>
        )

    };



    return (isLoading ? <Loading /> :
        <>
            <div className="container-fluid">
                <div className="position-absolute opacity-25 dark:opacity-25" style={{ minHeight: '100vh', background: `url('${state.event.image}') center center / cover`, left: '-30%', top: '-30%', width: '100%', filter: 'blur(100px)' }}>
                </div>
                <ToastContainer />
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-12" style={{ marginTop: "15px", textAlign: 'center' }}>
                                <img className="mb-4" src="/img/logo/logo.svg" alt="" style={{ height: '10vh' }} />
                            </div>
                        </div>
                        <div className="container-fluid container-sm p-0">
                            <div className="row g-0">
                                <div className="col-12 block-guestlist-enter">
                                    <div className="row p-4 p-sm-5">
                                        <div className="col-12 col-sm-4">


                                            <div className="row">
                                                <div className="col-12 ">
                                                    <img src={state.event.image} className="img-fluid img-event-single" />
                                                </div>
                                            </div>
                                            <div className="row mt-3 d-none d-sm-block">
                                                <SectionLocation />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-8">
                                            <div className="row mt-3 mt-sm-0 ">
                                                <div className="col-12">
                                                    {state.event.date_formated}
                                                    <small className="mx-3" style={{ color: 'rgb(234, 44, 97)' }}>|</small>
                                                    <span style={{ color: '#939393' }}>{state.event.hour_start} - {state.event.hour_end}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 mt-3">
                                                    <div className="row ">
                                                        <div className="col align-self-start ">
                                                            <div style={{ fontSize: '2rem', fontWeight: 'bold' }}>{state.event.name}</div>
                                                        </div>
                                                        <div className="col align-self-end text-end">
                                                            <BtnShareSocial />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12 mt-3">
                                                    {/* {state.event.description} */}
                                                    {state.event.description.split("\n").map(function (item, idx) {
                                                        return (
                                                            <span key={idx}>
                                                                {item}
                                                                <br />
                                                            </span>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            {state.event.guestlist_enabled ?
                                                (

                                                    <div className="row mt-5">
                                                        <div className="col-12">
                                                            <h4><small className="me-3" style={{ color: 'rgb(234, 44, 97)' }}>|</small>GUEST LIST</h4>

                                                            {!state.event.blockRegistry ? (

                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <form onSubmit={handlerSubmitGuestlist} className="p-3 position-relative z-2" style={{ backgroundColor: 'rgba(0, 0, 0, .3)' }}>
                                                                            {guestListRegister ? <GuestListSuccess /> : (
                                                                                <>
                                                                                    <div className="row">
                                                                                        <div className="col-12">
                                                                                            <Input
                                                                                                label="Nome"
                                                                                                name="name"
                                                                                                type="text"
                                                                                                value={state.form.name}
                                                                                                onPaste={(e) => { e.preventDefault(); handleChangeInputs({ target: { name: e.target.name, value: e.clipboardData.getData('Text') } }) }}
                                                                                                onChange={(e) => handleChangeInputs(e)}
                                                                                                errors={state.errors}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-12">
                                                                                            <Input
                                                                                                label="Email"
                                                                                                name="email"
                                                                                                type="text"
                                                                                                value={state.form.email}
                                                                                                onPaste={(e) => { e.preventDefault(); handleChangeInputs({ target: { name: e.target.name, value: e.clipboardData.getData('Text') } }) }}
                                                                                                onChange={(e) => handleChangeInputs(e)}
                                                                                                errors={state.errors}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-12 d-grid  mx-auto ">
                                                                                            {/* <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                                                                    <SelectCountries
                                                                                        label="&nbsp;"
                                                                                        name="phone_country"
                                                                                        value={state.form.phone_country}
                                                                                        onChange={(e) => handleChangeInputs(e)}
                                                                                        options={seriaizeCountryList()}
                                                                                        errors={state.errors}

                                                                                    />
                                                                                    <div className="ms-1">
                                                                                        <Input
                                                                                            label="Nº Telemóvel"
                                                                                            name="phone_number"
                                                                                            type="number"
                                                                                            value={state.form.phone_number}
                                                                                            onPaste={(e) => { e.preventDefault(); handleChangeInputs({ target: { name: e.target.name, value: e.clipboardData.getData('Text') } }) }}
                                                                                            onChange={(e) => handleChangeInputs(e)}
                                                                                            errors={state.errors}
                                                                                        />
                                                                                    </div>
                                                                                </div> */}
                                                                                            <div className="d-flex">
                                                                                                <div className="mt-2">
                                                                                                    <SelectCountries
                                                                                                        label="&nbsp;"
                                                                                                        name="phone_country"
                                                                                                        value={state.form.phone_country}
                                                                                                        onChange={(e) => handleChangeInputs(e)}
                                                                                                        options={seriaizeCountryList()}
                                                                                                        errors={state.errors}
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="flex-grow-1 ms-1">
                                                                                                    <Input
                                                                                                        label="Nº Telemóvel"
                                                                                                        name="phone_number"
                                                                                                        type="number"
                                                                                                        value={state.form.phone_number}
                                                                                                        onPaste={(e) => { e.preventDefault(); handleChangeInputs({ target: { name: e.target.name, value: e.clipboardData.getData('Text') } }) }}
                                                                                                        onChange={(e) => handleChangeInputs(e)}
                                                                                                        errors={state.errors}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row" style={{ marginTop: '30px' }}>
                                                                                        <div className="col-12">
                                                                                            <ReCAPTCHA size="invisible" ref={refCaptcha} theme="dark" sitekey="6LeJQrwpAAAAAHSzvB8Z_eA8SBZk9YBOBwBU6qyz" onChange={handleRecaptchaChange} />

                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row mb-5" style={{ marginTop: '30px' }}>
                                                                                        <div className="col-8 d-grid  mx-auto text-center">
                                                                                            <button type="submit" className="btn btn-primary" >Entrar na guestlist</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="row">
                                                                    <div className="col-12" >
                                                                        <div className="p-3 position-relative z-2" style={{ backgroundColor: 'rgba(0, 0, 0, .3)' }}>
                                                                            <div className="row p-3">
                                                                                <div className="col-12 col-sm-6 alert alert-danger">
                                                                                    <i className="fa-solid fa-times me-3 fs-3"></i>Guest list esgotada.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                ) : null}

                                            {state.event.tickets_enabled && state.tickets.length > 0 ? (
                                                <>
                                                    <div className="row mt-5">
                                                        <div className="col-12">
                                                            <div className="position-relative z-2">
                                                                <h4><small className="me-3" style={{ color: 'rgb(234, 44, 97)' }}>|</small>BILHETES</h4>
                                                                <TicketList />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </>
                                            ) : null}

                                            {state.event.tables_enabled ? (
                                                <div className="row mt-5">
                                                    <div className="col-12">
                                                        <div className="position-relative z-2">
                                                            <h4><small className="me-3" style={{ color: 'rgb(234, 44, 97)' }}>|</small>MESAS</h4>
                                                            <div className="box-tables ">

                                                                <FloorImage />
                                                                <TablesList />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}




                                            <div className="row mt-3 d-block d-sm-none">
                                                <SectionLocation />
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ModalTablesImage
                    show={modalImageTables.isVisible}
                    handleModalhide={() => setModalImageTables({
                        ...modalImageTables,
                        isVisible: false
                    })}
                    info={state.tabled_img_url}
                />
            </div>
            <div
                style={{
                    position: 'fixed',
                    bottom: 0,
                    width: '100%',
                    backgroundColor: '#141a1e',
                    padding: '10px 5px',
                    
                }}
            >
                <div className="row mb-1">
                    <div className="col-12 col-sm-9 text-center">
                        <div 
                        className={`${quantityTickets && Object.keys(quantityTickets).length > 0 ? 'd-block' : 'd-none'}`}
                        style={{height: '41px',
                            lineHeight: '41px',
                            fontSize: '1.1rem',
                            color: '#a9a9a9'}}>
                            {getTicketsSelectedQuantity()} x Bilhetes selecionados
                        </div>
                    </div>
                    <div className="col-12 col-sm-3 text-center">
                        <button 
                        className={`btn btn-primary mt-3 w-100 ${quantityTickets && Object.keys(quantityTickets).length > 0 ? 'd-block' : 'd-none'}`} 
                        onClick={() => handlerCreateTicketTransaction()}>Comprar · {getTicketsSelectedPrice()}€</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GuestListAccessEvent;