import react from "react";

const Checkbox = ({ label = "", value = "", valueCK = "", className = "form-check-input", readonly = false, errors = [],checkBoxChange, ...props }) => {
    let randForId = "field-" + Math.random(0, 9999);

    const hasError = !errors[props.name] ? "" : "is-invalid"
    const ErrorDialog = () => {
        return !errors[props.name] ? <></> :
            (
                <div className="invalid-feedback">
                    {errors[props.name][0]}
                </div>
            )
    }

    const handlerCheckBoxChange = (e) => {
        let arr = [...value, valueCK];
       checkBoxChange({target:{name: props.name, value: arr }})
    }
    console.log(value.includes(valueCK), value, valueCK)
    return (
        <div>
            
            <div className="form-check form-checkbox">
                <input className="form-check-input" type="checkbox" value={valueCK === null ? '' : valueCK}  id={randForId} checked={value.includes(valueCK) ? 'checked' : ''} readOnly={readonly} onClick={(e) => handlerCheckBoxChange(e)} {...props} />
                <label className="form-check-label" htmlFor={randForId}>
                {label}
                </label>
            </div>
            <ErrorDialog />
        </div>
    )
}

export default Checkbox;