import { useState, useRef, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import Request from "../../api/Requests";
import { toast, ToastContainer } from 'react-toastify';
const Menu = ({ tab, handleLoading }) => {
    const navigate = useNavigate();
    let paramsUrl = useParams();

    const menuContainerRef = useRef(null);
    const menuListRef = useRef(null);
    // const [isOverflowing, setIsOverflowing] = useState(false);
    // useEffect(() => {
    const getName = () => {
        return localStorage.getItem('nightspot_webapp_user_name') || ''; // Pega o nome da localStorage, ou string vazia
    };

    const isPromoter = () => {
        const ispromoter = localStorage.getItem('nightspot_webapp_user_is_promoter') || 0;
        return ispromoter == 'true' ? true : false;
    }

    const isVenueAdmin = () => {
        const isVenueAdmin = localStorage.getItem('nightspot_webapp_user_is_venue_admin') || 0;
        return isVenueAdmin == 'true' ? true : false;
    }

    const getNameBtnManager = () => {
        if (isVenueAdmin()) {
            return "Administrador";
        } else if (isPromoter) {
            return "Promotor";
        } else {
            return ""
        }
    }

    //     const checkOverflow = () => {
    //         if (menuListRef.current.scrollWidth > menuContainerRef.current.offsetWidth) {
    //             setIsOverflowing(true);
    //         } else {
    //             setIsOverflowing(false);
    //         }
    //     };

    //     // Verifica o overflow ao montar o componente e ao redimensionar a janela
    //     checkOverflow();
    //     window.addEventListener('resize', checkOverflow);

    //     // Cleanup do event listener ao desmontar o componente
    //     return () => {
    //         window.removeEventListener('resize', checkOverflow);
    //     };
    // }, []);

    // const handleScrollRight = () => {
    //     menuListRef.current.scrollBy({ left: 100, behavior: 'smooth' });
    // };

    const [showLeft, setShowLeft] = useState(false);
    const [showRight, setShowRight] = useState(false);

    const updateScrollButtons = () => {
        if (menuListRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = menuListRef.current;
            setShowLeft(scrollLeft > 0);
            setShowRight(scrollLeft < scrollWidth - (clientWidth + 50));
        }
    };

    const scrollMenu = (direction) => {
        if (menuListRef.current) {
            const scrollAmount = direction === 'left' ? -200 : 200;
            menuListRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        updateScrollButtons(); // Verifica ao carregar a página
        const ref = menuListRef.current;
        if (ref) {
            ref.addEventListener('scroll', updateScrollButtons);
            return () => ref.removeEventListener('scroll', updateScrollButtons);
        }
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h3>Olá {getName()}</h3>
                </div>
            </div>


            <div className="row">
                <div className="col-12 menu-container" ref={menuContainerRef}>
                    {showLeft && <button className="scroll-btn left" onClick={() => scrollMenu('left')}><i className="fa-solid fa-chevron-left"></i></button>}
                    <ul className="menu-list" ref={menuListRef}>
                        <li onClick={() => navigate('/my/qrcode')} className={`${tab === 'qrcode' ? 'active' : null}`}><i className="fa-solid fa-qrcode me-3"></i>Guest List</li>
                        <li onClick={() => navigate('/my/tickets')} className={`${tab === 'tickets' ? 'active' : null}`}><i className="fa-solid fa-ticket-alt me-3"></i>Bilhetes</li>
                        <li onClick={() => navigate('/my/tables')} className={`${tab === 'tables' ? 'active' : null}`}><i className="fa-solid fa-crown me-3"></i>Mesas VIP</li>


                        {!isPromoter() ? null : <li onClick={() => navigate('/my/promoters-dashboard')} className={`${tab === 'promoters-dashboard' ? 'active' : null}`}><i className="fa-solid fa-tachometer-alt me-2"></i>Gestão {getNameBtnManager()}</li>}

                    </ul>
                    {showRight && <button className="scroll-btn right" onClick={() => scrollMenu('right')}><i className="fa-solid fa-chevron-right"></i></button>}
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default Menu;