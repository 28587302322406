import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GuestlistAccessRequests from "./GuestlistAccessRequests";
import './Guestlist.css'
import Loading from "../../../components/Loading";

const GuestListAccess = ({ type }) => {
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const urlParams = useParams();
    const [state, setState] = useState({
        events: [],
        promoter: {
            name: '',
            profileImage: ''
        }
    })

    useEffect(async () => {
        let response = await GuestlistAccessRequests.getInfoSlug(urlParams, type);
        if (response && response.status == true) {
            setState(response.data)
        } else {
            //navigate(`/`);
        }
        setIsLoading(false)
    }, [])

    const PromoterImage = () => {
        if (state.promoter.profileImage && state.promoter.profileImage.length > 0) {
            return (
                <div className="user-img mb-3">
                    <img src={state.promoter.profileImage} className="img-fluid" />
                </div>
            )
        } else {
            return (
                <div className="user-img-txt">{state.promoter.name.length > 0 ?? state.promoter.name[0]}</div>
            )
        }
    }

    const AlertGuestlistExpirada = ({ data }) => {
        return null;
        // return (data.blockRegistry == 1 ?
        //     <div className="row mt-2">
        //         <div className="col-12 lh-1">
        //             <span className="badge bg-danger">Expirou</span>
        //         </div>
        //     </div>
        //     : null
        // )
    }

    const NoresultsEvents = () => {
        return (
            <div className="col-12">
                <div className="row box-events p-5">
                    <div className="col-12 text-center">
                        <i className="fa-solid fa-calendar-xmark" style={{ fontSize: '5rem', opacity: 0.25 }}></i>
                        <div className="mt-3">
                            <h5 className="text-center">Sem eventos</h5>
                            <p className="text-center">Neste momento não existem eventos agendados</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const Events = () => {
        if (state.events.length == 0) {
            return <NoresultsEvents />
        }

        return state.events.map((item) => {
            let url = "";
            // if (item.blockRegistry == 1) {
            //     url = "#"
            // } else {
            //     url = `/${type == `venue` ? `v` : `u`}/${urlParams.slug}/${item.slug}`;
            // }
            url = `/${type == `venue` ? `v` : `u`}/${urlParams.slug}/${item.slug}`;
            return (
                <div className="col-12 col-sm-4 mt-3 mx-2" key={item.id}>
                    <div className="row box-events  h-100">
                        <Link to={url}>
                            <div className="col-12 ">
                                <div className="row">
                                    <div className="col-3 p-0">
                                        <img src={item.image} className="img-fluid img-event" />
                                    </div>
                                    <div className="col-9 mt-3">
                                        <div className="container-fluid">
                                            <div className="row align-items-start">
                                                <div className="col-12 lh-1 fs-4">{item.name}</div>
                                            </div>
                                            <AlertGuestlistExpirada data={item} />
                                            <div className="row mt-2 align-items-end">
                                                <div className="col-12 mb-2 " style={{ fontSize: "0.7rem" }}>
                                                    {item.dateFormated}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div >
                </div>
            )
        })
    }

    return (isLoading ? <Loading /> :
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12" style={{ marginTop: "15px", textAlign: 'center' }}>
                            <img className="mb-4" src="/img/logo/logo.svg" alt="" style={{ height: '10vh' }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-12 align-items-center" style={{ marginTop: "15px", textAlign: 'center' }}>
                            <div style={{ display: 'inline-block' }}>
                                <PromoterImage />
                                <span className="user-txt">{state.promoter.name}</span>
                            </div>

                        </div>
                    </div>
                    <div className="container-fluid container-sm">
                        <div className="row mt-3">
                            <div className="col-12">
                                <h3>Eventos</h3>
                            </div>
                        </div>

                        <div className="row">
                            <Events />
                        </div>
                    </div>



                </div>
            </div>
        </div>

    )
}

export default GuestListAccess;