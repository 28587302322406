import { useEffect, useState } from "react";
import Request from "../../../api/Requests";
import { useNavigate, Link, useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import CompleteProfile from "../CompleteProfile/CompleteProfile";
import Menu from "../Menu";
const ScreenQrcode = () => {
    const navigate = useNavigate();
    let paramsUrl = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [state, setState] = useState(null)

    useEffect(() => {
        getGeneralInfo()
    }, [])

    const getGeneralInfo = async () => {
        const response = await Request('reserved-area/info', 'GET', {}, true);
        if (response && response.status) {
            setState(response.data);
            localStorage.setItem('nightspot_webapp_user_name', response.data.name);
            localStorage.setItem('nightspot_webapp_user_is_promoter', response.data.is_promoter);
            localStorage.setItem('nightspot_webapp_user_is_venue_admin', response.data.is_venue_admin);
        } else {
            navigate('/my/error')
        }
        setIsLoading(false)
    }

    const handlerUpdatUserInfo = async (form) => {
        setIsLoading(true)
        const response = await Request('reserved-area/update-user-info', 'POST', form, true);
        if (response && response.status) {
            getGeneralInfo()
        } else {
            navigate('/my/error')
            setIsLoading(false)
        }
    }

    const handleAcceptPromoterVenue = async (id) => {
        setIsLoading(true)
        const response = await Request('reserved-area/accept-promoter-venue', 'POST', { id: id }, true);
        if (response && response.status) {
            getGeneralInfo()
        } else {
            navigate('/my/error')
            setIsLoading(false)
        }
    }



    const EventList = () => {
        const SemResultados = () => {
            return (
                <div className="alert alert-info mt-3" role="alert">
                    <i className="fa-solid fa-info-circle me-3"></i>
                    Não estás inscrito em nenhuma guest list.
                </div>
            )
        }
        return (
            <div className=" mt-4 event-container">
                <h5 className="text-light">Eventos Guest List</h5>
                <div className="row">
                    <div className="col-12">
                        {state && state.qrcode.events && state.qrcode.events.length == 0 ? <SemResultados /> : state.qrcode.events.map((event, index) => (
                            <div
                                className={`row event-item ${index % 2 === 0 ? 'event-bg-light' : 'event-bg-dark'
                                    }`}
                                key={event.id}
                            >
                                <div className="col-3">
                                    <img src={event.banner} alt={event.name} className="img-fluid event-img" />
                                </div>
                                <div className="col-9">
                                    <h5 className="text-light">{event.name}</h5>
                                    <div className="mt-3" style={{ fontSize: '0.9rem' }}>
                                        {event.date_formated}
                                        <small className="mx-3" style={{ color: 'rgb(234, 44, 97)' }}>|</small>
                                        <span style={{ color: '#939393' }}>{event.hour_start} - {event.hour_end}</span>
                                    </div>

                                </div>
                                {/* <div className="col-2 d-flex justify-content-end align-items-center">
                                    <button className="btn btn-outline-light">QR Code</button>
                                </div> */}
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        );
    };

    const PromoterInvitations = () => {
        if (state.promoter_invitations && state.promoter_invitations.length > 0) {
            return state.promoter_invitations.map((item) => {
                return (
                    <div className="alert alert-primary" role="alert">
                        <h4 className="alert-heading">Convite</h4>
                        <div className="row">
                            <div className="col-12">
                                {item.text}
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12">
                                <button className="btn btn-secondary" onClick={() => handleAcceptPromoterVenue(item.id)}>Aceitar</button>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return null;
    }
    return (isLoading ? <Loading /> :
        <div className="container-fluid">
            <div className="row mb-3 justify-content-center">
                <div className="col-12 col-sm-6">
                    <div className="position-relative z-1">
                        <div className="box-tables mt-5">
                            {!state.validation_user_form ? <CompleteProfile formParam={state.user} handlerSubmit={(form) => handlerUpdatUserInfo(form)} /> : (
                                <>
                                    <Menu tab="qrcode" />

                                    <div className="row my-3 ">
                                        <div className="col-12">
                                            <h4>Qr Code de Acesso Guest list</h4>
                                            <div className="w-100 text-center">
                                                <div style={{ display: 'inline-block', backgroundColor: '#fff', borderRadius: '6px' }}>
                                                    <img style={{ display: 'block' }} src={state.qrcode.url} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <PromoterInvitations />
                                    <EventList />
                                </>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default ScreenQrcode;